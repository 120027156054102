import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import SideBar from "./../../Components/Sidebar";
import { Icon, Intent, Divider, Tag } from "@blueprintjs/core";
import AddMileageRateForCustomer from "./addMileageRateForCustomer";
import { GET, DELETE, POST, TenantID, PUT } from "./../dataSaver";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { format } from "date-fns";
import DeleteConfirmation from "../../Components/DeleteConfirmation";
import style from "./index.module.scss";
import LevelTwoHeader from "../../Components/LevelTwoHeader";
import CommonSwitch from '../../Components/CommonFields/CommonSwitch';
import CommonLabel from "../../Components/CommonFields/CommonLabel";
import CommonTextField from "../../Components/CommonFields/CommonTextField";
import CommonSelectField from "../../Components/CommonFields/CommonSelectField";
import CommonRadio from "../../Components/CommonFields/CommonRadio";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AddIcon from "@mui/icons-material/Add";

const AddInvoiceWorkflow = ({ edit, workflowData, selectedSite, setOpenInvoice }) => {
    const [showAddCompanyDialog, setShowAddCompanyDialog] = useState(false);
    const [title, setTitle] = useState([]);
    const [contractedServiceType, setContractedServiceType] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [entityId, setEntityId] = useState("");
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");
    const getAddMileageRateDialog = (value) => {
        setShowAddCompanyDialog(value);
    };
    const [workflowConfig, setWorkflowConfig] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isConditionRemoved, setIsConditionRemoved] = useState(false);
    const [controlLevels, setControlLevels] = useState([{
        '1': {
            "hasSpecificUserTitles": false,
            "userTitles": [],
            "workFlowStatus": {
                "status": 'APPROVED',
            },
            "reviewApproveRequirement": "ALL_INVOICE",
            "hasTriggerCondition": false,
            "conditions": []
        }
    }]);
    const [requiredDocumentation, setRequiredDocumentation] = useState(false);
    const [paymentConfirmationMethod, setPaymentConfirmationMethod] = useState("");
    const [certificationText, setCertificationText] = useState("");
    const [controlLevelFields, setControlLevelFields] = useState();
    const [approvedInvoicePaymentProcess, setApprovedInvoicePaymentProcess] = useState("");
    const [financeUserTitles, setFinanceUserTitles] = useState([]);
    const [conditionKeys, setConditionKeys] = useState({});

    const getIsExpanded = (value) => {
        setIsExpanded(value);
    };

    useEffect(() => {
        getContractedServiceType();
        getEntityData();
        getInvoiceWorkflow();
        getTitle();
    }, []);

    useEffect(() => {
        if (isConditionRemoved) {
            handleControlLevelFields();
            setIsConditionRemoved(false);
        }
    }, [isConditionRemoved])


    console.log('isOCnditio Removed', isConditionRemoved);

    useEffect(() => {
        handleControlLevelFields();
    }, [controlLevels, controlLevels?.length, title?.length])

    useEffect(() => {
        if (entityId !== "" && entityId !== undefined) {
            getLastModifiedDate();
        }
    }, [entityId]);

    const getContractedServiceType = async () => {
        const { data: contractedServiceType } = await GET(
            `entity-service/contractedServiceType`
        );
        setContractedServiceType(contractedServiceType);
    };

    const getEntityData = async () => {
        const { data: entity } = await GET(`entity-service/entity/${TenantID}`);
        setEntityId(entity?.id);
    };

    const getLastModifiedDate = async () => {
        const { data: lastModifiedDate } = await GET(
            `entity-service/referenceList/entity/${entityId}`
        );
        const date = new Date(lastModifiedDate.holidayList?.lastModified);
        setLastUpdatedDate(format(date, "MMM d, yyyy HH:mm"));
    };


    const getTitle = async () => {
        const { data: title } = await GET(`entity-service/functionalTitlesForCSPType`);
        setTitle(title);
    }
    console.log('Control Levels', controlLevels);

    const handleControlLevel = (controlLevel) => {
        let temp = [];
        for (let i = 0; i < controlLevel; i++) {
            temp.push({
                [i + 1]: {
                    "hasSpecificUserTitles": false,
                    "userTitles": [],
                    "reviewApproveRequirement": "ALL_INVOICE",
                    "workFlowStatus": {
                        "status": (i + 1 === controlLevel) ? 'APPROVED' : 'REVIEWED',
                    },
                    "hasTriggerCondition": false,
                    "conditions": []
                }
            })
        }
        setControlLevels(temp);
        handleControlLevelFields();
    }

    const getShowDeleteConfirmation = (value) => {
        setShowDeleteConfirmation(value);
    };

    const getDeleteConfirmation = (value) => {
        if (value) {
            // handleDeleteMileageRate(mileageId);
        }
    };

    const getInvoiceWorkflow = async () => {
        if (edit) {
            setWorkflowConfig(workflowData);
            let controlTemp = [];
            Object.keys(workflowData?.controlLevels)?.map((condition, index) => {
                controlTemp.push({ [condition]: workflowData?.controlLevels?.[condition] })
            })
            setControlLevels(controlTemp);
            setCertificationText(workflowData?.certificationText);
            setApprovedInvoicePaymentProcess(workflowData?.approvedInvoicePaymentProcess);
            setPaymentConfirmationMethod(workflowData?.paymentConfirmationMethod);
            setRequiredDocumentation(workflowData?.requireDocumentationOfInvoicePayment);
            setIsEdit(true);

            if (workflowData?.financeUserTitles) {
                setFinanceUserTitles(workflowData.financeUserTitles);
            }
        }


    }

    console.log('isEdit', isEdit, selectedSite);

    const handleFinanceUserTitleAdd = (titleId) => {
        console.log('Adding title with ID:', titleId);
        setIsUpdated(true);
        const selectedTitle = title?.find(t => t?.id === titleId);
        console.log('Selected title:', selectedTitle);

        if (selectedTitle) {
            setFinanceUserTitles([{
                title: selectedTitle.title,
                id: selectedTitle.id
            }]);
        }
    };

    const handleFinanceUserTitleRemove = (index) => {
        setIsUpdated(true);
        setFinanceUserTitles(prev => prev.filter((_, i) => i !== index));
    };

    const renderFinanceUserTitleTags = () => {
        return financeUserTitles.map((tag, index) => (
            <Tag
                key={index}
                onRemove={() => handleFinanceUserTitleRemove(index)}
                large={true}
                className={style.tagStyle}
            >
                {tag.title}
            </Tag>
        ));
    };

    const handleSave = async () => {
        if (isUpdated) {
            if (approvedInvoicePaymentProcess === '') {
                ErrorToaster('Select Approved Invoice Payment Process');
                return;
            }
            if (requiredDocumentation === '') {
                ErrorToaster('Select Required Documentation');
                return;
            }
            if (requiredDocumentation && paymentConfirmationMethod === '') {
                ErrorToaster('Select Payment Confirmation Method');
                return;
            }
            if (certificationText === '') {
                ErrorToaster('Enter Certification Text');
                return;
            }
            // if (approvedInvoicePaymentProcess === 'FINANCE_USER' && financeUserTitles.length === 0) {
            //     ErrorToaster('Select at least one Finance User Title');
            //     return;
            // }
            let emptyValue = false;
            controlLevels?.map((data, index) => {
                if (data?.[`${index + 1}`]['hasSpecificUserTitles'] && data?.[`${index + 1}`]['userTitles']?.length === 0) {
                    emptyValue = true;
                }
                if (data?.[`${index + 1}`]['hasTriggerCondition'] && data?.[`${index + 1}`]['conditions']?.length === 0) {
                    emptyValue = true;
                }
                else {
                    data?.[`${index + 1}`]['conditions']?.map((condition, conditionLevel) => {
                        if (condition?.conditionLabel === '') {
                            emptyValue = true;
                        }
                        condition?.triggerCondition?.map((trigger) => {
                            if (trigger?.field === '' || trigger?.comparisonOperator === '' || trigger?.value === '' || trigger?.unit === '' || trigger?.criteriaOperator === '') {
                                emptyValue = true;
                            }
                        })
                    })
                }

            });
            if (emptyValue) {
                ErrorToaster('Enter Valid Conditions (and / or) Condition Labels');
                return;
            }

            let controlLevelData = {};
            controlLevels?.map((data, index) => {
                controlLevelData[`${index + 1}`] = data[`${index + 1}`]
            })
            let data = {
                ...(isEdit && {
                    "id": workflowConfig?.id,
                }),
                // "site": { id: "66768ddbe1bf6b6a1390886b", name: "Tenet Healthcare Corporation" },
                "site": isEdit ? workflowData?.site : selectedSite,
                "noOfControlLevel": controlLevels?.length,
                "controlLevels": controlLevelData,
                "approvedInvoicePaymentProcess": approvedInvoicePaymentProcess,
                "requireDocumentationOfInvoicePayment": requiredDocumentation,
                "paymentConfirmationMethod": paymentConfirmationMethod,
                "certificationText": certificationText,
                "financeUserTitles": approvedInvoicePaymentProcess === 'FINANCE_USER' ? financeUserTitles : [],
            }

            console.log('workflow data', data, workflowData?.site, isEdit);

            if (isEdit) {
                await PUT(`timesheet-management-service/invoice/workflowConfig/${workflowConfig?.id}`, JSON.stringify(data))
                    .then((response) => {
                        SuccessToaster("Workflow Config Updated Successfully");
                    })
                    .catch((error) => {
                        ErrorToaster('Updating Workflow Config Failed');
                    });
            } else {
                await POST('timesheet-management-service/invoice/workflowConfig', JSON.stringify(data))
                    .then((response) => {
                        SuccessToaster("Workflow Config Added Successfully");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        ErrorToaster('Updating Workflow Config Failed');
                    });
            }
            setIsUpdated(false);
        }
    }

    const removeAndCondition = (controlLevel, conditionLevel, index) => {
        let controlTemp = [...controlLevels];
        controlTemp?.filter((control, index) => index === controlLevel)?.map((control => control?.[`${controlLevel + 1}`]['conditions']?.filter((control, indexValue) => indexValue === conditionLevel)?.map(condition => {
            let temp = condition?.['triggerCondition'];
            let filteredTemp = temp.filter((triggerCondition, triggerIndex) => triggerIndex !== index)?.map(data => data);
            condition['triggerCondition'] = filteredTemp;
        })));

        setControlLevels(controlTemp);
        setIsUpdated(true);
        handleControlLevelFields();
    }

    console.log('sites', selectedSite);

    const handleAndConditionFields = (controlLevel, conditionLevel) => {
        let temp = [];
        let andCondition = controlLevels?.filter((data, index) => index === controlLevel)?.map(data => data[`${controlLevel + 1}`]['conditions']?.filter((condition, indexValue) => conditionLevel === indexValue)?.map(condition => condition)[0])[0]?.['triggerCondition'];
        andCondition?.filter((data, index) => index !== 0)?.map((data, index) => {
            temp.push(<div>
                <CommonLabel value="And" className={style.purpleFont}
                />
                <div className={style.conditionGrid}>
                    <CommonSelectField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'field', index + 1)}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={"Select Trigger Condition"}
                        firstOptionValue={"0"}
                        valueList={['TOTAL_INVOICE_AMOUNT', 'INVOICE_ACTIVITY_TYPE']}
                        labelList={['Total Invoice Amount', 'Invoice Activity Type']}
                        disabledList={[false, false]}
                        value={data?.field}
                    />
                    <CommonSelectField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'comparisonOperator', index + 1)}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={"Select Comparison Operator"}
                        firstOptionValue={"0"}
                        valueList={['EQUAL', 'GREATER_THAN', 'LESS_THAN', 'GREATER_THAN_OR_EQUAL', 'LESS_THAN_OR_EQUAL', 'CONTAINS', 'NOT_CONTAINS']}
                        labelList={['Equal', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal', 'Contains', 'Not Contains']}
                        disabledList={[false, false, false, false, false]}
                        value={data?.comparisonOperator}

                    />
                    {data?.field === 'TOTAL_INVOICE_AMOUNT' ?
                        <CommonTextField
                            onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'totalInvoiceAmount', index + 1)}
                            className={`${style.fullWidth}`}
                            value={data?.totalInvoiceAmount}

                        /> : <CommonSelectField
                            onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'serviceType', index + 1)}
                            className={`${style.fullWidth}`}
                            firstOptionLabel={"Select Service Type"}
                            firstOptionValue={"0"}
                            valueList={contractedServiceType?.map(data => data?.serviceTypeTemplate)}
                            labelList={contractedServiceType?.map(data => data?.serviceType)}
                            disabledList={contractedServiceType?.map(data => false)}
                            value={data?.invoiceActivityType?.activityType}

                        />
                    }

                    <CommonSelectField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'unit', index + 1)}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={"Select Unit"}
                        firstOptionValue={"0"}
                        valueList={['PER_INVOICE', 'CONTRACT_YEAR', 'CONTRACT_PERIOD']}
                        labelList={['Per Invoice', 'Contract Year', 'Contract Period']}
                        disabledList={[false, false, false]}
                        value={data?.unit}

                    />
                    <Icon icon="trash" color="#52575D" className={style.marginTop10} onClick={() => removeAndCondition(controlLevel, conditionLevel, index + 1)} />

                </div>
            </div>)
        })
        return temp;
    }


    const handleConditionRemove = (controlLevel, conditionLevel) => {
        let temp = controlLevels;
        temp?.filter((control, index) => index === controlLevel)?.map((control => {
            let conditions = controlLevels?.filter((data, indexValue) => indexValue === controlLevel)?.map(data => data?.[`${controlLevel + 1}`]?.conditions)[0];
            let filteredCondition = conditions?.filter((data, index) => index !== conditionLevel)?.map(data => data);
            console.log('filtered condition', filteredCondition);
            temp[`${controlLevel}`][`${controlLevel + 1}`]['conditions'] = filteredCondition;
        }
        ));
        console.log('temp', temp)
        setControlLevels(temp);
        setIsConditionRemoved(true);
        // handleControlLevelFields();
    }
    const handleAddConditionFields = (index) => {
        let temp = []
        let conditions = controlLevels?.filter((data, indexValue) => indexValue === index)?.map(data => data?.[`${index + 1}`]?.conditions)[0];
        conditions?.map((data, indexValue) => {
            temp.push(
                <div
                    key={`condition-${index}-${indexValue}-${data.key || indexValue}`}
                    className={`${style.conditionCardStyle} ${style.marginTop20}`}
                >
                    <div className={style.crossIconGrid}>
                        <CommonTextField
                            key={`textfield-${index}-${indexValue}`}
                            type="text"
                            className={style.fullWidth}
                            onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'conditionLabel', 0)}
                            defaultValue={data?.conditionLabel}
                        />
                        <Icon
                            key={`cross-${index}-${indexValue}`}
                            icon="cross"
                            size={20}
                            intent={Intent.DANGER}
                            onClick={() => handleConditionRemove(index, indexValue)}
                        />
                    </div>

                    <div className={style.marginTop20}>
                        <CommonLabel value="when:"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <div className={style.conditionGrid}>
                            <CommonSelectField
                                onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'field', 0)}
                                key={`field-${index}-${indexValue}`}
                                className={`${style.fullWidth}`}
                                firstOptionLabel={"Select Trigger Condition"}
                                firstOptionValue={""}
                                valueList={['TOTAL_INVOICE_AMOUNT', 'INVOICE_ACTIVITY_TYPE']}
                                labelList={['Total Invoice Amount', 'Invoice Activity Type']}
                                disabledList={[false, false]}
                                value={data?.triggerCondition?.[0]?.field}
                            />
                            <CommonSelectField
                                onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'comparisonOperator', 0)}
                                key={`comparisonOperator-${index}-${indexValue}`}
                                className={`${style.fullWidth}`}
                                firstOptionLabel={"Select Comparison Operator"}
                                firstOptionValue={""}
                                valueList={['EQUAL', 'GREATER_THAN', 'LESS_THAN', 'GREATER_THAN_OR_EQUAL', 'LESS_THAN_OR_EQUAL', 'CONTAINS', 'NOT_CONTAINS']}
                                labelList={['Equal', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal', 'Contains', 'Not Contains']}
                                disabledList={[false, false, false, false, false]}
                                value={data?.triggerCondition?.[0]?.comparisonOperator}
                            />
                            {
                                data?.triggerCondition?.[0]?.field === 'TOTAL_INVOICE_AMOUNT' ?

                                    <CommonTextField
                                        onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'totalInvoiceAmount', 0)}
                                        key={`totalInvoiceAmount-${index}-${indexValue}`}
                                        className={`${style.fullWidth}`}
                                        value={data?.triggerCondition?.[0]?.totalInvoiceAmount}
                                    /> : <CommonSelectField
                                        onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'serviceType', 0)}
                                        key={`serviceType-${index}-${indexValue}`}
                                        className={`${style.fullWidth}`}
                                        firstOptionLabel={"Select Service Type"}
                                        firstOptionValue={""}
                                        valueList={contractedServiceType?.map(data => data?.serviceTypeTemplate)}
                                        labelList={contractedServiceType?.map(data => data?.serviceType)}
                                        disabledList={contractedServiceType?.map(data => false)}
                                        value={data?.triggerCondition?.[0]?.invoiceActivityType?.activityType}

                                    />
                            }

                            < CommonSelectField
                                onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'unit', 0)}
                                key={`unit-${index}-${indexValue}`}
                                className={`${style.fullWidth}`}
                                firstOptionLabel={"Select Unit"}
                                firstOptionValue={""}
                                valueList={['PER_INVOICE', 'CONTRACT_YEAR', 'CONTRACT_PERIOD']}
                                labelList={['Per Invoice', 'Contract Year', 'Contract Period']}
                                disabledList={[false, false, false]}
                                value={data?.triggerCondition?.[0]?.unit}
                            />
                            {
                                data['triggerCondition']?.length > 1 && <Icon icon="trash" color="#52575D" className={style.marginTop10} onClick={() => removeAndCondition(index, indexValue, 0)} />
                            }

                        </div >

                        {handleAndConditionFields(index, indexValue)}

                        < div className={`${style.addIcon} ${style.marginTop20}`}>
                            <AddIcon
                                key={`add-${index}-${indexValue}`}
                                sx={{ fontSize: 25 }}
                                className={style.addIconButton}
                                onClick={() => {
                                    handleAndCondition(index, indexValue);
                                }}
                            />
                        </div >
                    </div >
                </div >
            )
        })
        return temp;
    }

    const handleValues = (label, value, index) => {
        setIsUpdated(true);
        let temp = controlLevels;
        temp?.filter((data, indexValue) => indexValue === index)?.map(data => {
            if (label !== 'userTitles') {
                data[`${index + 1}`][label] = value;
            }
            else if (label === 'userTitles') {
                if (data[`${index + 1}`][label].length >= 3) {
                    ErrorToaster('Maximum of 3 control level titles are allowed');
                    return;
                }
                let selectedTitle = title?.filter(data => data?.id === value)?.map(data => data)?.[0];
                data[`${index + 1}`][label].push({ title: selectedTitle?.title, id: selectedTitle?.id })
            }
            if (label === 'hasTriggerCondition' && value === true) {
                data[`${index + 1}`]['conditions'] = [
                    {
                        "conditionLabel": "",
                        "triggerCondition": [
                            {
                                "field": "TOTAL_INVOICE_AMOUNT",
                                "comparisonOperator": "",
                                "totalInvoiceAmount": "",
                                "unit": "",
                                "invoiceActivityType": {
                                    "activityType": ""
                                },
                                "criteriaOperator": "AND"
                            }
                        ]
                    }]
            }
            else if (label === 'hasTriggerCondition' && value === false && data[`${index + 1}`]['reviewApproveRequirement'] === 'CONDITIONAL_INVOICE') {
                data[`${index + 1}`][label] = true;
            }
            else if (label === 'hasTriggerCondition' && value === false) {
                data[`${index + 1}`]['conditions'] = [];
            }
            else if (label === 'reviewApproveRequirement' && value === 'CONDITIONAL_INVOICE') {
                data[`${index + 1}`]['hasTriggerCondition'] = true;
                data[`${index + 1}`]['conditions'] = [
                    {
                        "conditionLabel": "",
                        "triggerCondition": [
                            {
                                "field": "TOTAL_INVOICE_AMOUNT",
                                "comparisonOperator": "",
                                "totalInvoiceAmount": "",
                                "unit": "",
                                "invoiceActivityType": {
                                    "activityType": ""
                                },
                                "criteriaOperator": "AND"
                            }
                        ]
                    }]
            } else {

            }
        })
        setControlLevels(temp);
        handleControlLevelFields();
    }
    const handleAddCondition = (index) => {
        setIsUpdated(true);
        let temp = controlLevels;
        const newKey = Math.random().toString(36).substr(2, 9);

        temp?.filter((data, indexValue) => indexValue === index)?.map(data => {
            data[`${index + 1}`]['conditions'].push({
                "key": newKey,
                "conditionLabel": "",
                "triggerCondition": [
                    {
                        "field": "TOTAL_INVOICE_AMOUNT",
                        "comparisonOperator": "",
                        "totalInvoiceAmount": "",
                        "unit": "",
                        "invoiceActivityType": {
                            "activityType": ""
                        },
                        "criteriaOperator": "AND"
                    }
                ]
            })
        })
        setControlLevels(temp);
        handleControlLevelFields();
    }

    const handleAndCondition = (controlLevel, conditionLevel) => {
        setIsUpdated(true);
        let temp = controlLevels;
        temp?.filter((data, indexValue) => indexValue === controlLevel)?.map(data => {
            data[`${controlLevel + 1}`]['conditions']?.filter((condition, index) => index === conditionLevel)?.map(condition => {
                condition?.['triggerCondition']?.push({
                    "field": "TOTAL_INVOICE_AMOUNT",
                    "comparisonOperator": "",
                    "totalInvoiceAmount": "",
                    "unit": "",
                    "invoiceActivityType": {
                        "activityType": ""
                    },
                    "criteriaOperator": "AND"
                })
            })
        });
        setControlLevels(temp);
        handleControlLevelFields();
    }

    const titleTags = (userTitle, controlLevel) => {
        let titleFields = [];
        userTitle
            // ?.filter((data) => userTitle.map((userTitle) => userTitle?.id === userTitle?.id))
            ?.map((tag, index) => {
                const onRemove = () => {
                    let temp = controlLevels;
                    temp?.filter((data, controllerIndex) => controllerIndex === controlLevel)?.map((data) => {
                        data[`${controlLevel + 1}`]['userTitles'] = data[`${controlLevel + 1}`]['userTitles']?.filter((title, titleIndex) => titleIndex !== index)?.map(title => title);

                    })
                    setControlLevels(temp);
                    handleControlLevelFields();
                };
                titleFields.push(
                    <Tag
                        // key={index}
                        onRemove={onRemove}
                        large={true}
                        className={style.tagStyle}
                    >
                        {tag?.title}
                    </Tag>
                );
            });
        return titleFields;
    }


    const handleControlLevelFields = () => {
        let field = [];

        controlLevels?.map((data, index) => {
            field.push(<div >
                <div className={style.marginTop20}>
                    <CommonLabel
                        value={`Control Level ${index + 1} Setup Reviewer / Approver`}
                        className={style.boldText}
                    />
                </div>
                <div className={style.padding20}>
                    <div
                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                    >
                        <CommonLabel value="By Specific User Titles"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <div>
                            <div className={`${style.displayInRow} `}>
                                <div>
                                    <CommonSwitch
                                        checked={data[`${index + 1}`]['hasSpecificUserTitles']}
                                        // disabled={
                                        //     selectContractInfo === "INDIVIDUAL" && true
                                        // }
                                        key={`user${index}`}
                                        className={`${style.switchFontStyle} ${style.textAlignLeft} ${style.flexLeft}`}
                                        onChange={(e) => handleValues('hasSpecificUserTitles', e.target.checked, index)}
                                        label={
                                            data[`${index + 1}`]['hasSpecificUserTitles'] ? "YES" : "NO"
                                        }
                                    />
                                </div>
                                {
                                    data[`${index + 1}`]['hasSpecificUserTitles'] &&
                                    <div>
                                        <div>
                                            <CommonSelectField
                                                onChange={(e) => handleValues('userTitles', e.target.value, index)}
                                                className={`${style.userTitleWidth}`}
                                                firstOptionLabel={"Select User Title"}
                                                firstOptionValue={"0"}
                                                valueList={title?.map(data => data?.id)}
                                                labelList={title?.map(data => data?.title)}
                                                disabledList={title?.map(data =>
                                                    data[`${index + 1}`]?.userTitles?.length >= 3 || false
                                                )}
                                            />
                                        </div>
                                        <div className={`${style.marginTop20}`}>
                                            {titleTags(data[`${index + 1}`]['userTitles'], index)}
                                        </div>
                                        {data[`${index + 1}`]?.userTitles?.length >= 3 &&
                                            <div className={style.helperText}>
                                                {/* Note: Maximum of 3 control level titles are allowed */}
                                            </div>
                                        }
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                    <div
                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                    >
                        <CommonLabel value="Review & Approval Requirement"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <CommonRadio
                            className={`${style.leftAlign}`}
                            value={data[`${index + 1}`]['reviewApproveRequirement']}
                            onChange={(e) => handleValues('reviewApproveRequirement', e.target.value, index)}
                            radioValue={['ALL_INVOICE', 'CONDITIONAL_INVOICE']}
                            label={['Required For All Invoices', 'Only Applies to Invoices meeting Focused Review Conditions']}
                        />
                    </div>
                    <div
                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                    >
                        <CommonLabel value="Setup Trigger Conditions for Invoices requiring focused Review"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <div className={`${style.spaceBetween}`}>
                            <div >
                                <CommonSwitch
                                    checked={data[`${index + 1}`]['hasTriggerCondition']}
                                    className={`${style.switchFontStyle} ${style.textAlignLeft} ${style.flexLeft}`}
                                    onChange={(e) => handleValues('hasTriggerCondition', e.target.checked, index)}
                                    label={
                                        data[`${index + 1}`]['hasTriggerCondition'] ? "YES" : "NO"
                                    }
                                />
                            </div>
                            <div>
                                <button
                                    className={`${style.newContractOutlinedButton} ${style.marginTop10}  ${data[`${index + 1}`]['hasTriggerCondition'] ? style.cursorPointer : style.disabledPointer
                                        }`}
                                    onClick={() => { data[`${index + 1}`]['hasTriggerCondition'] && handleAddCondition(index); }}
                                >
                                    ADD CONDITION
                                </button>
                            </div>

                        </div>

                    </div>
                    {handleAddConditionFields(index)}
                </div>
                <Divider className={style.marginTop20} />
            </div>)

        })

        setControlLevelFields(field)
    }


    const handleValueCondition = (controlLevel, conditionLevel, value, label, triggerConditionIndex) => {
        let temp = controlLevels;
        setIsUpdated(true);
        temp?.filter((data, indexValue) => indexValue === controlLevel)?.map((data, index) => {
            data?.[`${controlLevel + 1}`]?.['conditions']?.filter((condition, index) => index === conditionLevel)?.map(condition => {
                if (label === "conditionLabel") {
                    condition[label] = value;
                } else if (label === "serviceType") {
                    const selectedService = contractedServiceType.find(
                        service => service.serviceTypeTemplate === value
                    );
                    console.log('Selected service activityTypeTemplate:', selectedService?.activityTypeTemplate);
                    condition['triggerCondition'][triggerConditionIndex]['invoiceActivityType'] = {
                        activityType: selectedService?.serviceTypeTemplate || '',
                        activityTypeTemplate: selectedService?.activityTypeTemplate || ''
                    };
                }
                else {
                    condition['triggerCondition'][triggerConditionIndex][label] = value;
                }
            })
        })
        setControlLevels(temp);
        handleControlLevelFields();
    }

    useEffect(() => {
        console.log('Current finance user titles:', financeUserTitles);
    }, [financeUserTitles]);

    return (
        <div>
            <div>

                <div>
                    <div className={`${style.centreCardStyle} ${style.padding20}`}>
                        <div className={style.twoCol}>
                            <div className={`${style.twoCol} ${style.marginTop20} `}>
                                <CommonLabel
                                    value="Control Levels to Setup for Invoice Approval"
                                    className={style.boldText}
                                />
                                <div>
                                    <CommonSelectField
                                        value={controlLevels?.length}
                                        onChange={(e) => {
                                            handleControlLevel(parseInt(e.target.value));
                                        }}
                                        className={`${style.fullWidth} `}
                                        firstOptionLabel={"Select Control Level"}
                                        firstOptionValue={""}
                                        valueList={[1, 2, 3, 4, 5]}
                                        labelList={[1, 2, 3, 4, 5]}
                                        disabledList={[false, false, false, false, false]}
                                    />
                                </div>

                            </div>
                        </div>
                        <br />
                        <div className={style.marginTop20}>
                            {controlLevelFields}
                        </div>
                        <div
                            className={`${style.addManagerGrid} ${style.marginTop20} `}
                        >
                            <CommonLabel value="Approved Invoice Payment Processing"
                            />
                            <CommonRadio
                                className={`${style.leftAlign}`}
                                value={approvedInvoicePaymentProcess}
                                onChange={(e) => {
                                    setApprovedInvoicePaymentProcess(e.target.value);
                                    setIsUpdated(true);
                                    if (e.target.value !== 'FINANCE_USER') {
                                        setFinanceUserTitles([]);
                                    }
                                }}
                                radioValue={['FINANCE_USER', 'EXTERNAL_SYSTEM']}
                                label={['By Finance User (PDF Printout)', 'Through External System Interface']}
                            />
                        </div>
                        {approvedInvoicePaymentProcess === 'FINANCE_USER' && (
                            <div className={`${style.addManagerGrid} ${style.marginTop20} ${style.marginLeft20}`}>
                                <CommonLabel value="Finance User Title" />
                                <div>
                                    <CommonSelectField
                                        onChange={(e) => handleFinanceUserTitleAdd(e.target.value)}
                                        className={`${style.halfWidth}`}
                                        firstOptionLabel={"Select Finance User Title"}
                                        firstOptionValue={"0"}
                                        valueList={title?.map(data => data?.id)}
                                        labelList={title?.map(data => data?.title)}
                                        disabledList={title?.map(() => false)}
                                        value={financeUserTitles[0]?.id || "0"}
                                    />
                                </div>
                            </div>
                        )}
                        <Divider className={style.marginTop20} />
                        <div
                            className={`${style.addManagerGrid} ${style.marginTop20} `}
                        >
                            <CommonLabel value="Require Documentation of Invoice Payment"
                            />
                            <div>
                                <div className={`${style.displayInRow} `}>
                                    <CommonSwitch
                                        checked={requiredDocumentation}
                                        className={`${style.switchFontStyle} ${style.textAlignLeft} ${style.flexLeft}`}
                                        onChange={() => { setRequiredDocumentation(!requiredDocumentation); setIsUpdated(true); }}
                                        label={
                                            requiredDocumentation ? "YES" : "NO"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {requiredDocumentation && (
                            <div
                                className={`${style.addManagerGrid} ${style.marginTop20} ${style.marginLeft20}`}
                            >
                                <CommonLabel value="Payment Confirmation Method"
                                />
                                <CommonRadio
                                    className={`${style.leftAlign}`}
                                    value={paymentConfirmationMethod}
                                    onChange={(e) => { setPaymentConfirmationMethod(e.target.value); setIsUpdated(true); }}
                                    radioValue={['MANUAL', 'EXTERNAL_SYSTEM']}
                                    label={['Manual Data Entry (Paid Invoice Data)', 'Through External System Interface']}
                                />
                            </div>
                        )}
                        <Divider className={style.marginTop20} />
                        <div
                            className={`${style.addManagerGrid} ${style.marginTop20} `}
                        >
                            <CommonLabel value="Reviewer & Approver Certification text for signing off on Approved Invoices"
                            // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                            />
                            <div>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={
                                        certificationText
                                    }
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setCertificationText(data);
                                        setIsUpdated(true);
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                "height",
                                                "150px",
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    config={{
                                        placeholder: 'Type your content here...',
                                    }}
                                />
                            </div>
                        </div>

                        <div className={`${style.floatRight} ${style.marginTop10}`}>
                            <button
                                className={style.outlinedButton}
                                onClick={() => {
                                    setIsUpdated(false);
                                    setOpenInvoice(false);
                                }}
                            >
                                CANCEL
                            </button>
                            {isUpdated && (
                                <button
                                    className={`${style.buttonStyle} ${style.marginLeft20}`}
                                    onClick={() => handleSave()}
                                >
                                    SAVE
                                </button>
                            )}
                        </div>

                    </div >
                </div >
            </div >
            {/* <div className={style.spaceBetween}>
                <p className={style.poweredBy}>Powered by - TimeSmartAI.Inc LLP</p>
                <p className={style.poweredBy}>© TimeSmartAI.Inc</p>
            </div> */}
        </div >
    );
};

export default AddInvoiceWorkflow;
