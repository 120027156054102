import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import SideBar from "./../../Components/Sidebar";
import { GET, DELETE, POST, TenantID, PUT } from "./../dataSaver";
import { format } from "date-fns";
import style from "./index.module.scss";
import LevelTwoHeader from "../../Components/LevelTwoHeader";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Table from '../../Components/TableDesign';
import EditIcon from '@mui/icons-material/Edit';
import SearchBar from './../../Components/SearchBar';
import { H4 } from "@blueprintjs/core";
import AddInvoiceWorkflowPopup from "./addInvoiceWorkflowPopup";
import AddInvoiceWorkflow from "./addInvoiceWorkflow";
import LoadingScreen from "../../Components/LoadingScreen";
import { useReactToPrint } from "react-to-print";
import { createRef, useCallback, useRef } from "react";
import { Auth, GetEntityDetails, currentUser } from "../../utils/auth";
import Loader from './../../images/loading.gif';


const MultiSiteInvoiceWorkflow = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [show, setShow] = useState("");
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");
    const [invoiceWorkflow, setInvoiceWorkflow] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [openAddWorkflow, setOpenAddWorkflow] = useState(false);
    const [selectedSite, setSelectedSite] = useState({ id: '0' });
    const [isEdit, setIsEdit] = useState(false);
    const [workflowData, setWorkflowData] = useState();
    const [isPrintClicked, setIsPrintClicked] = useState(false);
    const PDFRef = createRef();
    const componentRef = useRef(null);
    const [filteredWorkflow, setFilteredWorkflow] = useState([]);
    const loggedInUser = currentUser();
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('DESC');
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedSearchKey, setDebouncedSearchKey] = useState('');

    const tableSortValues = [
        false, // empty column
        false, // Site - removed sorting
        true,  // Control Levels
        false, // Focused Review Conditions
        false, // Invoice Processing By
        false, // Post Payment Documentation By
        false, // Last Updated by
        true   // Last Updated
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchKey(searchKey);
        }, 300); // 300ms delay

        return () => clearTimeout(timer);
    }, [searchKey]);

    useEffect(() => {
        const fetchData = async () => {
            await getInvoiceWorkflow();
        };
        fetchData();
    }, [debouncedSearchKey, sortField, sortDirection]);

    const getInvoiceWorkflow = async () => {
        try {
            setIsLoading(true);
            const params = new URLSearchParams();

            if (debouncedSearchKey?.trim()) {
                params.append('searchText', debouncedSearchKey.trim());
                params.append('searchField', 'SITE_NAME');
            }

            console.log('Current sortField:', sortField);
            console.log('Current sortDirection:', sortDirection);

            if (sortField) {
                const sortFieldMapping = {

                    'noOfControlLevel': 'NO_CONTROL_LEVELS',
                    'lastModifiedDate': 'LAST_MODIFIED_DATE'
                };

                const backendSortField = sortFieldMapping[sortField];
                if (backendSortField) {
                    params.append('sortField', backendSortField);
                    params.append('sortDirection', sortDirection);
                }
            }

            let url = `timesheet-management-service/invoice/workflowConfig`;
            const queryString = params.toString();
            if (queryString) {
                url += `?${queryString}`;
            }

            console.log('API URL:', url);

            const response = await GET(url);
            console.log('API Response:', response);

            let responseData = response?.data;

            if (!responseData) {
                setInvoiceWorkflow([]);
                setFilteredWorkflow([]);
                return;
            }

            const formattedData = Array.isArray(responseData) ? responseData : [responseData];
            setInvoiceWorkflow(formattedData);
            setFilteredWorkflow(formattedData);

        } catch (error) {
            console.error('Error fetching workflow data:', error);
            setInvoiceWorkflow([]);
            setFilteredWorkflow([]);
        } finally {
            setIsLoading(false);
        }
    };

    const getSearchKey = (value) => {
        setSearchKey(value);
    };

    const getActiveContractsValues = () => {
        if (!filteredWorkflow?.length) {
            return Array(8).fill({ type: "text", value: [] });
        }

        const transformedData = filteredWorkflow.map(data => ({
            dot: 'yellow',
            site: data?.site?.name || '',
            controlLevel: data?.noOfControlLevel || 0,
            focusedReview: (() => {
                let focused = false;
                const levels = data?.controlLevels || {};
                for (const level of Object.values(levels)) {
                    if (level?.conditions?.length > 0) {
                        focused = true;
                        break;
                    }
                }
                return focused ? 'Yes' : 'No';
            })(),
            invoiceProcessingBy: data?.requireDocumentationOfInvoicePayment ? 'Yes' : 'No',
            documentation: data?.paymentConfirmationMethod === 'MANUAL'
                ? 'Manual Data Entry'
                : data?.paymentConfirmationMethod === 'EXTERNAL_SYSTEM'
                    ? 'External System Interface'
                    : '-',
            lastUpdated: data?.lastModifiedDate
                ? format(new Date(data.lastModifiedDate), 'MM-dd-yyyy')
                : data?.createdDate
                    ? format(new Date(data.createdDate), 'MM-dd-yyyy')
                    : 'Not available',
            lastUpdatedBy: data?.lastUpdatedBy || `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
        }));

        return [
            { type: "dot", value: transformedData.map(d => d.dot), tooltipValue: '' },
            { type: "text", value: transformedData.map(d => d.site), onClickFunction },
            { type: "text", value: transformedData.map(d => d.controlLevel) },
            { type: "text", value: transformedData.map(d => d.focusedReview) },
            { type: "text", value: transformedData.map(d => d.invoiceProcessingBy) },
            { type: "text", value: transformedData.map(d => d.documentation) },
            { type: "text", value: transformedData.map(d => d.lastUpdatedBy) },
            { type: "text", value: transformedData.map(d => d.lastUpdated) }
        ];
    };

    const onClickFunction = (data) => {
        console.log('data edit', data);
        setOpenInvoice(true);
        setIsEdit(true);
        setWorkflowData(data);
    }

    const handleClose = (value) => {
        setShow(!value);
    }

    const handleSelectedSite = (value) => {
        setSelectedSite(value)
    }

    const setOpenInvoice = (value) => {
        setOpenAddWorkflow(value);
    }

    const getIsExpanded = (value) => {
        setIsExpanded(value);
    };

    const tableHeader = [
        '',
        'Site',
        'Control Levels',
        'Focused Review Conditions',
        'Invoice Processing By',
        'Post Payment Documentation By',
        'Last Updated by',
        'Last Updated'
    ];

    const tableDataValues = getActiveContractsValues();

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `InvoiceWorkflow_${format(new Date(), 'MM_dd_yy')}`,
        removeAfterPrint: true
    });

    const handleSort = (columnIndex, direction) => {
        console.log('Sort clicked - Raw inputs:', {
            columnIndex,
            direction,
            currentSortField: sortField,
            currentSortDirection: sortDirection
        });

        // If columnIndex is undefined, try to get it from the header mapping
        if (columnIndex === undefined) {
            console.log('Column index undefined, using header mapping');
            const headerMapping = {
                'NO_CONTROL_LEVELS': 2,
                'LAST_MODIFIED_DATE': 7
            };
            columnIndex = headerMapping[direction] || 2;

            // Changed this line - don't override the direction if it's passed
            if (!direction || direction === 'NONE') {
                direction = sortDirection === 'DESC' ? 'ASC' : 'DESC';
            }
        }

        // Map column indices to their corresponding backend fields
        const sortFieldMapping = {
            2: 'noOfControlLevel',
            7: 'lastModifiedDate'
        };

        // Get the field to sort by
        const newSortField = sortFieldMapping[columnIndex];
        if (!newSortField) {
            console.log('Invalid column for sorting:', columnIndex);
            return;
        }

        // Simplified direction logic
        let newDirection;
        if (direction === 'ASCENDING' || direction === 'ASC') {
            newDirection = 'ASC';
        } else if (direction === 'DESCENDING' || direction === 'DESC') {
            newDirection = 'DESC';
        } else {
            // Toggle direction if same field is clicked
            newDirection = sortDirection === 'DESC' ? 'ASC' : 'DESC';
        }

        console.log('Setting new sort state:', {
            newSortField,
            newDirection
        });

        setSortField(newSortField);
        setSortDirection(newDirection);
    };

    return (
        <Fragment>
            <div>
                <Navbar />
                <div className={style.margin20}>
                    <div className={style.hidePrintableContainer} ref={componentRef}>
                        <div className={style.printTable}>
                            <h2>Invoice Review/Approval Workflow Setup</h2>
                            {isLoading ? (
                                <div className={`${style.verticalAlignCenter} ${style.justifyCenter}`}>
                                    <img src={Loader} alt="Loading" width={250} />
                                </div>
                            ) : (
                                <Table
                                    tableHeaderValues={tableHeader}
                                    tableDataValues={tableDataValues || []}
                                    tableData={filteredWorkflow || []}
                                    gridStyle={style.multiInvoiceWorkflowGrid}
                                    actions={[]}
                                    heading={'There are no Invoice Workflows to manage'}
                                    tableSortValues={tableSortValues}
                                    getHandleSort={handleSort}
                                    sortValue={{
                                        sortByField: sortField,
                                        sortBy: sortDirection === 'ASC' ? 'ASCENDING' : 'DESCENDING'
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div className={`${isExpanded ? style.bigCardGrid : style.smallCardGrid}`}>
                        <div>
                            <SideBar isExpanded={isExpanded} getIsExpanded={getIsExpanded}>
                                <div></div>
                            </SideBar>
                        </div>
                        <div>
                            <LevelTwoHeader
                                heading={"INVOICE REVIEW / APPROVAL WORKFLOW SETUP"}
                                path={"/Screens/ReferenceList/customerAdminDashboard"}
                                callingFrom={"Customer Admin"}
                                needHeader={true}
                            />

                            <div>
                                {!openAddWorkflow ? (
                                    <div className={`${style.centreCardStyle} ${style.padding20}`}>
                                        <div className={style.workflowHeaderGrid}>
                                            <p className={`${style.workflowSiteFont} ${style.marginLeft40}`}>
                                                BY SITE
                                            </p>
                                            <div className={style.marginLeft}>
                                                <SearchBar
                                                    getSearchKey={getSearchKey}
                                                    searchKey={searchKey}
                                                    className={style.searchBarStyle}
                                                    placeholder="Search by site name"
                                                />
                                            </div>
                                            <div className={`${style.marginTop10} ${style.marginLeft20} ${style.cursorPointer}`}
                                                onClick={() => handlePrint()}>
                                                <PrintOutlinedIcon sx={{
                                                    fontSize: isPrintClicked ? 20 : 25,
                                                    color: isPrintClicked ? '#fff' : '#857AEF'
                                                }} />
                                            </div>
                                            <div className={`${style.marginTop10}`}>
                                                {/* <FilterAltOutlinedIcon sx={{ fontSize: 25, color: '#857AEF' }} /> */}
                                            </div>
                                            <button className={`${style.addWorkflowStyle} ${style.marginTop10}`} onClick={() => setShow(true)}>REVIEW / APPROVAL SETUP</button>
                                        </div>
                                        <div className={style.marginTop20}>
                                            {isLoading ? (
                                                <div className={`${style.verticalAlignCenter} ${style.justifyCenter}`}>
                                                    <img src={Loader} alt="Loading" width={250} />
                                                </div>
                                            ) : (
                                                <Table
                                                    tableHeaderValues={tableHeader}
                                                    tableDataValues={tableDataValues || []}
                                                    tableData={filteredWorkflow || []}
                                                    gridStyle={style.multiInvoiceWorkflowGrid}
                                                    actions={[]}
                                                    heading={'There are no Invoice Workflows for you to manage'}
                                                    tableSortValues={tableSortValues}
                                                    getHandleSort={handleSort}
                                                    sortValue={{
                                                        sortByField: sortField,
                                                        sortBy: sortDirection === 'ASC' ? 'ASCENDING' : 'DESCENDING'
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ) : <AddInvoiceWorkflow openAddWorkflow={openAddWorkflow} edit={isEdit} workflowData={workflowData} setOpenInvoice={setOpenInvoice} selectedSite={selectedSite} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={style.spaceBetween}>
                        <p className={style.poweredBy}>Powered by - TimeSmartAI.Inc LLP</p>
                        <p className={style.poweredBy}> TimeSmartAI.Inc</p>
                    </div>
                </div>
            </div>


            {show && (<AddInvoiceWorkflowPopup existingWorkflows={invoiceWorkflow} show={show} handleClose={handleClose} setOpenAddWorkflow={setOpenAddWorkflow} handleSelectedSite={handleSelectedSite} />)}

        </Fragment >
    );
};

export default MultiSiteInvoiceWorkflow;
