/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
    Dialog,
    Classes,
    Icon,
    Intent
} from "@blueprintjs/core";
import { GET, DELETE, POST, TenantID, PUT } from "./../dataSaver";
import style from "./index.module.scss";
import CommonSelectField from "../../Components/CommonFields/CommonSelectField";
import AddInvoiceWorkflow from "./addInvoiceWorkflow";
import RedWarning from "./../../images/redWarning.png";
import { ErrorToaster } from "../../utils/toaster";

const AddInvoiceWorkflowPopup = ({
    show,
    handleClose,
    setOpenAddWorkflow,
    handleSelectedSite,
    existingWorkflows
}) => {

    const [entityData, setEntityData] = useState();
    const [selectedSite, setSelectedSite] = useState({ id: '0' });

    useEffect(() => {
        getEntityData();
    }, [])

    const getEntityData = async () => {
        const { data: entity } = await GET(`entity-service/entity/${TenantID}`);
        setEntityData(entity);
    };

    // Filter out sites that already have workflows
    // const getAvailableSites = () => {
    //     if (!entityData?.sites || !existingWorkflows) return [];

    //     return entityData.sites.filter(site => {
    //         const siteName = site?.siteName?.siteName;
    //         if (!siteName) return false;

    //         // Check if this site already has a workflow
    //         const hasWorkflow = existingWorkflows.some(workflow =>
    //             workflow?.site?.name?.toLowerCase() === siteName?.toLowerCase()
    //         );
    //         return !hasWorkflow;
    //     });
    // }

    const handleSiteChange = (siteId) => {
        const site = entityData?.sites?.find(site => site?.id === siteId);
        if (site) {
            const newSiteData = {
                id: site.id,
                name: site?.siteName?.siteName
            };
            setSelectedSite(newSiteData);
            handleSelectedSite(newSiteData);
        }
    }

    const handleContinueClick = () => {
        if (selectedSite?.id !== '0') {
            handleClose(true);
            setOpenAddWorkflow(true);
        } else {
            ErrorToaster('Select a Site to Create Invoice Workflow');
        }
    }
    let availableSites = [];

    entityData?.sites?.filter(site => !existingWorkflows?.map(workflow => workflow?.site?.id).includes(site.id))?.map(site => {
        availableSites.push({ id: site?.id, name: site?.siteName?.siteName })
    });

    // getAvailableSites();
    // console.log('availableSites:', availableSites);
    // console.log('Available sites details:', {
    //     total: availableSites.length,
    //     names: availableSites.map(site => site?.siteName?.siteName),
    //     ids: availableSites.map(site => site?.id)
    // });

    console.log('Existing Workflow', existingWorkflows, availableSites, entityData);

    return (
        <Dialog
            isOpen={show}
            onClose={handleClose}
            className={`${style.addInvoiceDialog} ${style.dialogPaddingBottom}`}
        >
            <div
                className={`${Classes.DIALOG_BODY} ${style.deleteEcecutedContractDialogBackground}  ${style.margin20}`}
            >

                <div className={style.spaceBetween}>
                    <p className={style.extensionStyle}>Select Site To Setup Approver / Reviewer Workflow</p>
                    <Icon icon="cross" size={20} intent={Intent.DANGER} className={style.addInvocieCrossStyle} onClick={() => handleClose(true)} />
                </div>
                <div className={style.extensionBorder}></div>

                {availableSites.length === 0 ? (
                    <div className={`${style.positionCenter} ${style.marginTop20}`}>
                        <p className={style.noSitesMessage}>No sites available for workflow setup</p>
                    </div>
                ) : (
                    <CommonSelectField
                        value={selectedSite?.id}
                        onChange={(e) => { handleSiteChange(e.target.value) }}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={
                            "Select Site Name"
                        }
                        firstOptionValue={"0"}
                        valueList={availableSites.map(site => site?.id)}
                        labelList={availableSites.map(site => site?.name)}
                        disabledList={availableSites.map(() => false)}
                    />
                )}

                {availableSites.length > 0 && (
                    <div className={`${style.positionCenter} ${style.marginTop20}`}>
                        <button
                            className={`${style.cloneButtonStyle} ${style.marginLeft20} ${style.cursorPointer}`}
                            onClick={handleContinueClick}
                        >
                            Go To Setup
                        </button>
                    </div>
                )}
            </div>
        </Dialog>
    );
};
export default AddInvoiceWorkflowPopup;
